<template>
  <div class="analysis">
    <div class="header">
      <div class="container clearfix">
        <div class="logo-box">
          <router-link to="/"></router-link>
<!--          <span> · 交易分析神器</span>-->
        </div>
        <div class="right-box">
          <router-link to="/" title="首页"><i class="el-icon-s-home"></i></router-link>
          <UserMenu />
        </div>
        <div class="change-account-box">
          <el-col class="type-btn-box">
            <el-button-group>
              <el-button :class="analysisType === 'single' ? 'selected' : ''"
                         @click="changeType('single')">单账户分析</el-button>
              <el-button :class="analysisType === 'multiple' ? 'selected' : ''"
                         @click="changeType('multiple')">多账户分析</el-button>
            </el-button-group>
            <el-popover
              placement="bottom"
              title="已选择账户"
              width="200"
              trigger="hover"
              >
              <div v-for="item in accounts" :key="item.id">
                <p v-show="selectedAccountId.indexOf(item.id) !== -1">{{item.nickname}} ({{item.account_code}})</p>
              </div>
              <el-button slot="reference" class="accout-button" type="text" icon="el-icon-s-platform">
                {{curAccountName}}{{curAccount}}
              </el-button>
            </el-popover>
          </el-col>
        </div>
      </div>
    </div>
    <div class="analysis-main container">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-card class="menu-card">
            <el-menu :default-active="activeName" :unique-opened="true" :router="true">
              <el-submenu index="1" v-show="analysisType === 'single'">
                <template slot="title">
                  <i class="left_icon stat_icon"></i>
                  <span>综合统计</span>
                  <i class="right_icon dowm_icon"></i>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="(analysisType =='single') || (analysisType =='multiple'&&item.supportMulti)"
                                v-for="item in statArr" :key="item.path" :index="item.path">
                    {{item.name}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <i class="left_icon income_icon"></i>
                  <span>收益分析</span>
                  <i class="right_icon dowm_icon"></i>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="(analysisType =='single') || (analysisType =='multiple'&&item.supportMulti)"
                                v-for="item in incomeArr" :key="item.path" :index="item.path">
                    {{item.name}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <i class="left_icon trans_icon"></i>
                  <span>交易特征</span>
                  <i class="right_icon dowm_icon"></i>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="(analysisType =='single') || (analysisType =='multiple'&&item.supportMulti)"
                                v-for="item in transArr" :key="item.path" :index="item.path">
                    {{item.name}}
                  </el-menu-item>
                  <!--<el-menu-item index="dispersion">交易分散度</el-menu-item>-->
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="5">
                <template slot="title">
                  <i class="left_icon analysy_icon"></i>
                  <span>品种分析</span>
                  <i class="right_icon dowm_icon"></i>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="(analysisType =='single') || (analysisType =='multiple'&&item.supportMulti)"
                                v-for="item in varietyArr" :key="item.path" :index="item.path">
                    {{item.name}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="4" v-show="analysisType =='single'">
                <template slot="title">
                  <i class="left_icon origin_icon"></i>
                  <span>原始数据</span>
                  <i class="right_icon dowm_icon"></i>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-show="(analysisType =='single') || (analysisType =='multiple'&&item.supportMulti)"
                                v-for="item in originArr" :key="item.path" :index="item.path">
                    {{item.name}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item :key="'kline'" :index="'kline'" class="kine-item"  v-show="analysisType =='single'">
                <template slot="title">
                  <i class="left_icon kline_icon"></i>
                  <span>K线复盘</span>
                </template>
              </el-menu-item>
            </el-menu>
          </el-card>
        </el-col>
        <el-col :span="19" class="right-box">
          <router-view/>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <p>© 2019 大盘手网络科技 <a class="icp" href="http://beian.miit.gov.cn">渝ICP备19015980号-1</a></p>
    </div>
    <el-dialog :title="tempAnalysisType === 'single' ?'选择单账户':'选择多账户'" :visible.sync="accountDialogVisible" width="800px" class="account-dialog" @closed="onClose">
      <el-row :gutter="20" class="accounts">
        <el-col :span="8" v-for="(item) in accounts" :key="item.id" class="mt20">
          <el-card :class="selectedAccountId.indexOf(item.id) !== -1 ? ' selected' : ''"
                   @click.native="changeCurAccount(item.id)">
            <p>{{item.nickname}}</p>
            <p>({{item.account_code}})</p>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <el-row>
<!--                    <el-col :span="16" class="type-btn-box">-->
<!--                        <el-button-group>-->
<!--                          <el-button :class="tempAnalysisType === 'single' ? 'selected' : ''"-->
<!--                                     @click="changeType('single')">单账户分析</el-button>-->
<!--                          <el-button :class="tempAnalysisType === 'multiple' ? 'selected' : ''"-->
<!--                                     @click="changeType('multiple')">多账户分析</el-button>-->
<!--                        </el-button-group>-->
<!--                    </el-col>-->
                    <el-col :offset="16" :span="8">
                        <el-button type="warning" class="ok-btn" @click="handleOk">确 定</el-button>
                    </el-col>
                </el-row>
            </span>
    </el-dialog>
  </div>
</template>

<script>
  import UserMenu from "../components/UserMenu";
export default {
  name: "Analysis",
  components: {
    UserMenu
  },
  data() {
    return {
      visible: false,
      activeSubTab: JSON.parse(localStorage.getItem('activeSubTab')) || 1,
      selectedAccountId: this.$store.state.curAccountId,
      accountDialogVisible: false,
      analysisType: this.$store.state.analysisType || 'single',
      tempAnalysisType: this.$store.state.analysisType || 'single',
      statArr: [{name: '统计报表', path: 'report', supportMulti: false}],
      incomeArr: [
        {name: '累计净值', path: 'netValue', supportMulti: true},
        {name: '历史权益', path: 'equities', supportMulti: true},
        {name: '盈亏曲线', path: 'profitCurve', supportMulti: true},
        {name: '收益率分布', path: 'yieldDist', supportMulti: true},
        {name: '夏普比率', path: 'sharp', supportMulti: true},
        // {name: '收益回撤比', path: 'returnRatio', supportMulti: false},
        {name: '每周盈亏', path: 'weekProfit', supportMulti: true},
        {name: '每月盈亏', path: 'monthProfit', supportMulti: true},
        {name: '盈亏TOP10', path: 'topTen', supportMulti: true}
      ],
      transArr: [
        {name: '持仓周期', path: 'takeCycle', supportMulti: true},
        {name: '周期盈亏', path: 'cycleProfit', supportMulti: true},
        {name: '周期胜率', path: 'cycleOdds', supportMulti: true},
        {name: '成交结构', path: 'tradeStruct', supportMulti: true},
        {name: '品种盈亏', path: 'varietyProfit', supportMulti: true},
        {name: '品种胜率', path: 'varietyOdds', supportMulti: true},
        {name: '多空仓位', path: 'longShort', supportMulti: true},
        {name: '多单盈亏', path: 'longProfit', supportMulti: false},
        {name: '空单盈亏', path: 'shortProfit', supportMulti: false},
        {name: '隔夜仓位', path: 'overnight', supportMulti: false},
        {name: '单边敞口', path: 'exposure', supportMulti: false}
      ],
      originArr: [
        {name: '逐日结算', path: 'dailySett', supportMulti: false},
        {name: '成交明细', path: 'tradeDetail', supportMulti: false},
        {name: '持仓明细', path: 'takeDetail', supportMulti: false},
        // {name: '持仓汇总', path: 'takeTotal', supportMulti: false}
      ],
      varietyArr: [
        {name: '盈亏曲线', path: 'singleVariety', supportMulti: true},
        {name: '每周盈亏', path: 'varietyWeekProfit', supportMulti: true},
        {name: '每月盈亏', path: 'varietyMonthProfit', supportMulti: true},
      ]
    };
  },
  computed: {
    activeName: function () {
      return this.$route.name
    },
    accounts: function () {
      return this.$store.state.accounts;
    },
    curAccount: function () {
      if (this.$store.state.curAccountId.length > 1) {
        return '';
      }
      for (let item in this.accounts) {
        if (this.accounts[item].id === this.$store.state.curAccountId[0]) {
          return '(' + this.accounts[item].account_code + ')';
        }
      }
      return '';
    },
    curAccountName: function () {
      if (this.$store.state.curAccountId.length > 1) {
        return '已选择多账号';
      }
      for (let item in this.accounts) {
        if (this.accounts[item].id === this.$store.state.curAccountId[0]) {
          return this.accounts[item].nickname;
        }
      }
      return '';
    }
  },
  methods: {
    openAccountDialog() {
      this.selectedAccountId = JSON.parse(JSON.stringify(this.$store.state.curAccountId));
      this.tempAnalysisType = this.$store.state.analysisType;
      this.accountDialogVisible = true
    },
    onClose () {
      this.selectedAccountId = JSON.parse(JSON.stringify(this.$store.state.curAccountId));
      this.tempAnalysisType = this.$store.state.analysisType;
    },
    changeCurAccount(accountId) {
      if (this.tempAnalysisType === 'multiple') {
        let index = this.selectedAccountId.indexOf(accountId);
        if (index !== -1) {
          this.selectedAccountId.splice(index, 1);
        } else {
          this.selectedAccountId.push(accountId);
        }
      } else {
        this.selectedAccountId = [accountId];
      }
    },
    async handleOk() {
      if (this.selectedAccountId.length < 1) {
        this.$message({
          message: '请选择账户',
          type: 'warning'
        });
        return false;
      }
      if (this.tempAnalysisType === 'multiple' && this.selectedAccountId.length < 2) {
        this.$message({
          message: '请至少选择两个账户',
          type: 'warning'
        });
        return false;
      }
      this.$store.commit('SET_CUR_ACCOUNT_ID', this.selectedAccountId);
      this.accountDialogVisible = false;
      if ((this.tempAnalysisType === 'multiple') && (this.$store.state.analysisType === 'single')) {
        await this.$router.push('/analysis/netValue');
        this.$router.go(0);
      } else {
        this.$router.go(0);
      }
      this.$store.commit('SET_ANALYSIS_TYPE', this.tempAnalysisType);
    },
    changeType(type) {
      // if (type !== 'single') {
      //   this.$message({
      //     message: '功能研发中...',
      //     type: 'warning'
      //   });
      //   return false;
      // }
      this.openAccountDialog()
      if (type !== 'multiple') {
        if (this.selectedAccountId.length >= 1) {
          this.selectedAccountId = [this.selectedAccountId[0]];
        } else {
          this.selectedAccountId = [this.accounts[0].id];
        }
      }
      this.tempAnalysisType = type;
    }
  },
  beforeCreate() {
    if (this.$store.state.accounts.length === 0) {
      this.$message({
        message: '请先添加期货监控中心账户或等待数据更新',
        type: 'warning'
      });
      this.$router.push({name: 'Account'})
    } else if (this.$store.state.curAccountId.length === 0) {
      let curAccount = this.$store.state.accounts[0];
      this.$store.commit('SET_CUR_ACCOUNT_ID', [curAccount.id]);
    }
  }
}
</script>

<style lang="scss">
  .analysis {
    .mt20 {
      margin-top: 20px;
    }

    .header {
      .logo-box {
        float: left;
        text-align: left;
        padding-top: 9px;

        a {
          display: inline-block;
          width: 216px;
          height: 38px;
          background-image: url("../assets/qiweihu_logo.png");
          background-size: 216px 38px;
          float: left;
        }

        span {
          line-height: 45px;
          margin-left: 10px;
          font-size: 14px;
        }
      }

      .right-box {
        float: right;
        display: flex;
        align-items: center;

        i {
          font-size: 26px;
          color: #666666;
          line-height: 60px;
          margin-right: 20px;
        }
      }

      .change-account-box {
        float: right;
        margin-right: 30px;
        color: #666666;
        .type-btn-box {
          text-align: left;
          .el-button {
            &:hover, &:focus {
              background: none;
              color: #DD593B;
              border-color: #DCDFE6;
            }

            &.selected {
              transition: none;
              background-image: url("../assets/selected_bg.png");
              background-position: right bottom;
              background-size: 25px 25px;
              background-repeat: no-repeat;
              color: #DD593B;
            }
          }
          .accout-button {
            margin-left: 20px;
            padding: 0;
            border: 0;
            color: #666666;
            font-size: 16px;
            max-width: 244px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover, &:focus {
              background: none;
              color: #666666;
            }
          }
        }


        i {
          line-height: 60px;
        }
      }

      .cur-account-info {
        font-size: 14px;
        margin-right: 20px;
      }
    }

    .analysis-main {
      margin: 20px auto;
      min-height: 830px;
      .right-box {
        margin-bottom: 80px;
      }

      .menu-card {
        .el-menu {
          border: 0;
          .el-submenu {
            .el-submenu__title {
              margin-bottom: 10px;
              padding: 0;
              display: flex;
              align-items: center;
              font-size: 16px;
              .left_icon {
                width: 24px;
                height: 24px;
                &.stat_icon {
                  background-image: url("../assets/analysis/stat_icon.png") ;
                  background-size: 24px 24px;
                }
                &.income_icon {
                  background-image: url("../assets/analysis/income_icon.png") ;
                  background-size: 24px 24px;
                }
                &.trans_icon {
                  background-image: url("../assets/analysis/trans_icon.png") ;
                  background-size: 24px 24px;
                }
                &.origin_icon {
                  background-image: url("../assets/analysis/origin_icon.png") ;
                  background-size: 24px 24px;
                }
                &.analysy_icon {
                  background-image: url("../assets/analysis/analysy_icon.png") ;
                  background-size: 24px 24px;
                }
              }
              > span {
                height: 24px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 24px;
                color: #333333;
                margin: 0 15px;
                &.active {
                  color: #3598DB;
                }
              }
              .right_icon {
                width: 12px;
                height: 8px;
                &.dowm_icon {
                  background-image: url("../assets/analysis/down_arrow_icon.png") ;
                  background-size: 12px 8px;
                }
              }
              .el-submenu__icon-arrow {
                display: none;
              }
            }
            /*&.active {*/
            /*  .el-submenu__title {*/
            /*    margin-bottom: 10px;*/
            /*    background: #FFFFFF;*/
            /*    box-shadow: 0px 4px 6px rgba(53, 152, 219, 0.3);*/
            /*    color: #3598DB;*/
            /*    border-radius: 5px;*/
            /*  }*/
            /*}*/
            .el-menu-item-group {
              .el-menu-item-group__title {
                display: none;
              }
              .el-menu-item {
                height: 44px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 44px;
              }
            }
            &.is-opened {
              .el-submenu__title {
                margin-bottom: 10px;
                background: #FFFFFF;
                box-shadow: 0px 4px 6px rgba(53, 152, 219, 0.3);
                color: #3598DB;
                border-radius: 5px;
                .left_icon {
                  &.stat_icon {
                    background-image: url("../assets/analysis/stat_active_icon.png") ;
                    background-size: 24px 24px;
                  }
                  &.income_icon {
                    background-image: url("../assets/analysis/income_active_icon.png") ;
                    background-size: 24px 24px;
                  }
                  &.trans_icon {
                    background-image: url("../assets/analysis/trans_active_icon.png") ;
                    background-size: 24px 24px;
                  }
                  &.origin_icon {
                    background-image: url("../assets/analysis/origin_active_icon.png") ;
                    background-size: 24px 24px;
                  }
                  &.analysy_icon {
                    background-image: url("../assets/analysis/analysy_active_icon.png") ;
                    background-size: 24px 24px;
                  }
                }
                .right_icon {
                  /*transform: rotate(180deg);*/
                  &.dowm_icon {
                    background-image: url("../assets/analysis/up_arrow_icon.png") ;
                    background-size: 12px 8px;
                  }
                }
                > span {
                  color: #3598DB;
                }
              }

            }
          }
          .kine-item {
            text-align: left;
            .left_icon {
              width: 24px;
              height: 24px;
              &.kline_icon {
                background-image: url("../assets/analysis/kline_icon.png") ;
                background-size: 24px 24px;
                display: inline-block;
              }
            }
            span {
              height: 24px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #333333;
              margin: 0 15px;
            }
            &.is-active {
              .left_icon.kline_icon {
                background-image: url("../assets/analysis/kline_active_icon.png") ;
              }
              span {
                color: #409EFF;
              }
            }
          }
          
        }
      }

      .el-card {
        .chart {
          margin: 50px 0;
        }
      }

      .blackboard {
        box-sizing: border-box;
        height: 220px;
        width: 100%;
        background: url("../assets/blackboard.png");
        background-size: 100% 100%;
        color: #FFFFFF;
        padding: 10px 210px 20px 25px;
        text-align: left;

        p {
          font-size: 14px;
          margin: 10px 0;
          color: #f5f5f5;
        }

        .star-level {
          line-height: 24px;

          .el-rate {
            display: inline-block;

            i {
              font-size: 24px;
            }
          }
        }
      }
    }

    .account-dialog {
      & > .el-dialog {
        & > .el-dialog__body {
          padding: 0 20px 20px 20px;
        }
      }

      .accounts {
        .el-card {
          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(255, 223, 215);
            border-color: rgba(255, 223, 215);
          }

          p {
            line-height: 24px;
            font-size: 14px;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.selected {
            transition: none;
            background-image: url("../assets/selected_bg.png");
            background-position: right bottom;
            background-size: 50px 50px;
            background-repeat: no-repeat;
          }
        }
      }

      .dialog-footer {
        .type-btn-box {
          text-align: left;

          .el-button {
            &:hover, &:focus {
              background: none;
              color: #DD593B;
              border-color: #DCDFE6;
            }

            &.selected {
              transition: none;
              background-image: url("../assets/selected_bg.png");
              background-position: right bottom;
              background-size: 25px 25px;
              background-repeat: no-repeat;
              color: #DD593B;
            }
          }
        }

        .ok-btn {
          background: #DD593B;
          color: #FFFFFF;
          border-color: #DD593B;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
    .footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 1000;
    }

  }

</style>
